const decision = {
  decisionName: ["scrapPercentage", "scrapGroupId", "test"],
  linkedTable: ["new.ScrapGroups", "new.Wares", "new.Material"],
  newValue: ["5", "9.5", "10", "3000"],
  currentValue: ["All", "5", "9.5", "10", "1000"],
};

//TODO - final form
const decisionFinalForm = {
  ChangeScrapPercentage: {
    table: "new.Wares",
    columnName: "scrapGroupId",
    linkedTable: "new.ScrapGroups",
    newValue: [
      { val: 5, id: 3 },
      { val: 9.5, id: 2 },
      { val: 10, id: 1 },
    ],
    currentValue: [
      { val: "All", id: "*" },
      { val: 5, id: 3 },
      { val: 9.5, id: 2 },
      { val: 10, id: 1 },
    ],
  },
};

//currentForm
const template: any = {
  scrapGroupId: {
    decisionName: {
      value: "scrapGroupId",
      label: "Change Wares Scrap Group",
    },
    linkedTable: [{ value: "new.Wares", label: "Wares" }],
    dataSource: {
      table: "new.ScrapGroups",
      fields: ["scrapPercentage", "scrapGroupId"],
    },
    newValue: (data: any) =>
      data.map((d: any) => {
        return { label: d.scrapPercentage, value: d.scrapGroupId };
      }),
    currentValue: (data: any) => {
      return [
        { label: "All", value: "*" },
        ...data.map((d: any) => {
          return { label: d.scrapPercentage, value: d.scrapGroupId };
        }),
      ];
    },
  },
  storageUnitId: {
    decisionName: {
      value: "storageUnitId",
      label: "Change Works Storage Unit",
    },
    linkedTable: [{ value: "new.Works", label: "Works" }],
    dataSource: {
      table: "new.StorageUnits",
      fields: ["longName", "storageUnitId"],
    },
    newValue: (data: any) =>
      data.map((d: any) => {
        return {
          label: d.longName,
          value: d.storageUnitId,
        };
      }),
    currentValue: (data: any) => {
      return [
        { label: "All", value: "*" },
        ...data.map((d: any) => {
          return { label: d.longName, value: d.storageUnitId };
        }),
      ];
    },
  },
};

export default template;
