const axios = require("axios");

import { baseURL, getAzToken } from "../../../../../config/azToken";

const GetTableDataProducer: producer = async ({
  decisionName = prop.decisionName,
  isOpen = observe.isOpen,
  getHistory = observe.histories,
  setHistory = update.histories[prop.decisionName],
}) => {
  if (!isOpen.decisionName && getHistory[decisionName]) return;

  const payload = {
    method: "get",
    url: "/history",
    baseURL,
    params: { code: getAzToken(), decision: decisionName },
    headers: {
      "content-type": "application/json",
    },
    responseType: "json",
  };

  try {
    const { data } = await axios(payload);
    setHistory.set(data);
  } catch (e) {
    console.log(e);
  }
};

export default GetTableDataProducer;
