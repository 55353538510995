import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import RoofingIcon from "@mui/icons-material/Roofing";

import { useIsAuthenticated } from "@azure/msal-react";
import LoginProducer from "./producers/loginProducer";
import LogoutProducer from "./producers/logoutProducer";

const Navbar: view = ({ login = update.login, logout = update.logout }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <RoofingIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".12rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Calculation System
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {!useIsAuthenticated() ? (
            <Button color="inherit" onClick={() => login.set(true)}>
              Login
            </Button>
          ) : (
            <Button color="inherit" onClick={() => logout.set(true)}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

Navbar.producers([LoginProducer, LogoutProducer]);

export default Navbar;
