import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import History from "../history/history";

import SyncOneDecisionProducer from "./producers/syncOneDecisionProducer";

import template from "../../utils/sqlTemplate";

const Row: view = ({
  row = prop.row,
  getDecisionData = observe.decisionData,
  isOpen = observe.isOpen,
  setIsOpen = update.isOpen[prop.row.DecisionName],
  setSyncOneDecisionFlag = update.syncOneDecisionFlag[prop.row.Id],
}) => {
  if (!Object.keys(getDecisionData).length) return;

  const {
    Created,
    DecisionName,
    Description,
    UpdateCondition,
    Value,
    WasProcessed,
  } = row;

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const decisionData = getDecisionData[DecisionName];
  const _newValue = template[DecisionName]["newValue"](decisionData);
  const _currentValue = template[DecisionName]["currentValue"](decisionData);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen.set(!isOpen[DecisionName])}
          >
            <Switch checked={isOpen[DecisionName] || false} disabled={false} />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {template[DecisionName]["decisionName"]["label"]}
        </TableCell>
        <TableCell align="center">
          {
            _newValue?.find(
              ({ value }: { value: any }) => value == parseInt(Value)
            ).label
          }
        </TableCell>
        <TableCell align="center">{Description}</TableCell>
        <TableCell align="center">{WasProcessed ? "\u2713" : "X"}</TableCell>
        <TableCell align="center">
          {
            UpdateCondition.includes("= *")
              ? "ALL"
              : "Where " +
                UpdateCondition.split("=").shift() +
                "is " +
                _currentValue?.find(
                  ({ value }: { value: any }) =>
                    value == parseInt(UpdateCondition.split("=").pop())
                )?.label /*UpdateCondition.replaceAll(/=/g, "is")*/
          }
        </TableCell>
        <TableCell align="center">
          {new Date(Created).toLocaleDateString("en-US", dateOptions)}
        </TableCell>
        <TableCell align="center">
          <Button
            variant="contained"
            size="medium"
            onClick={() => setSyncOneDecisionFlag.set(true)}
          >
            SYNC
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen[DecisionName]} timeout="auto" unmountOnExit>
            <History decisionName={DecisionName} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.producers([SyncOneDecisionProducer]);

export default Row;
