import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CardComponent from "../../common/card/cardComponent";
import { CalculationCard } from "../../cards/calculationCard";
import PopulationCard from "../../cards/populationCard/populationCard";
import CostEstimationCard from "../../cards/costEstimationCard/costEstimationCard";
import { isAdmin } from "../../config/azToken";

const HomeView: view = ({}) => {
  return (
    <div style={{ marginTop: "100px" }}>
      <Typography
        style={{ textAlign: "center" }}
        variant="h2"
        component="div"
        gutterBottom
      >
        OBW Calculation System
      </Typography>
      <Box
        component="span"
        sx={{ display: "flex", mx: "2px", transform: "scale(0.8)" }}
        style={{ flexDirection: "row", justifyContent: "space-evenly" }}
      >
        {isAdmin() && (
          <PopulationCard />
        )}
        <CalculationCard />
        <CostEstimationCard />
        <CardComponent
          data={{
            activityName: "Package Calculation",
            cardTitle: "Start Packages",
            cardSubtitle: "Go To",
            description:
              "Going to this page you can start a new package calculation flow",
            path: "packageCalculation",
          }}
        />
        {isAdmin() && (
          <CardComponent
            data={{
              activityName: "Decisions Activity",
              cardTitle: "Decisions",
              cardSubtitle: "Go To",
              description:
                "On this page you can make decisions that affect your calculation",
              path: "decisions",
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default HomeView;
