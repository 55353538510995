const axios = require("axios");

import {functionAppURL, getAzToken} from "../../../../../config/azToken";

const SyncOneDecisionProducer: producer = async ({
  row = prop.row,
  getSyncOneDecisionFlag = observe.syncOneDecisionFlag,
  setSyncOneDecisionFlag = update.syncOneDecisionFlag[prop.row.Id],
  setDecisionStatus = update.decisions[prop.row.DecisionName]["WasProcessed"],
}) => {
  if (!getSyncOneDecisionFlag[row.Id]) return;

  const payload = {
    method: "post",
    url: `${functionAppURL}/decisions/syncOne`,
    params: { code: getAzToken() },
    headers: {
      "content-type": "application/json",
    },
    data: { Id: row.Id },
    responseType: "json",
  };

  setSyncOneDecisionFlag.set(false);

  try {
    await axios(payload);
    setDecisionStatus.set(true);
  } catch (e) {
    console.log(e);
    setDecisionStatus.set(false);
    return;
  }
};

export default SyncOneDecisionProducer;
