import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import getDecisionHistoryProducers from "./producers/getDecisionHistoryProducer";

import template from "../../utils/sqlTemplate";
import GetAllHistoryProducer from "./producers/getAllHistoryProducer";

const History: view = ({
  decisionName = prop.decisionName,
  getHistory = observe.histories,
  getDecisionData = observe.decisionData,
  setLoadMoreHistoryFlag = update.loadMoreHistoryFlag[prop.decisionName],
}) => {
  if (!getHistory[decisionName]) return;

  const history: any = getHistory[decisionName];
  const historyLength = getHistory[decisionName].length;

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const decisionData = getDecisionData[decisionName];
  const _newValue = template[decisionName]["newValue"](decisionData);
  const _currentValue = template[decisionName]["currentValue"](decisionData);

  return !history || historyLength === 0 ? (
    <p>No History</p>
  ) : (
    <React.Fragment>
      <Box sx={{ margin: 1 }}>
        <Typography variant="h6" gutterBottom component="div">
          History
        </Typography>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>Last Modified Date</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">New Value</TableCell>
              <TableCell align="right">Update Condition</TableCell>
              <TableCell align="right">Was Processed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map(
              ({
                LastModified,
                UpdateCondition,
                Value,
                WasProcessed,
                Id,
                Description,
              }: any) => (
                <TableRow key={Id}>
                  <TableCell component="th" scope="row">
                    {new Date(LastModified).toLocaleDateString(
                      "en-US",
                      dateOptions
                    )}
                  </TableCell>
                  <TableCell align="right">{Description}</TableCell>
                  <TableCell align="right">
                    {
                      _newValue?.find(
                        ({ value }: { value: any }) => value == parseInt(Value)
                      ).label
                    }
                  </TableCell>
                  <TableCell align="right">
                    {UpdateCondition.includes("= *")
                      ? "ALL"
                      : "Where " +
                        UpdateCondition.split("=").shift() +
                        "was " +
                        _currentValue?.find(
                          ({ value }: { value: any }) =>
                            value == parseInt(UpdateCondition.split("=").pop())
                        )?.label}
                  </TableCell>
                  <TableCell align="right">
                    {WasProcessed ? "\u2713" : "X"}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        {historyLength <= 3 ? (
          <Button
            variant="text"
            onClick={() => setLoadMoreHistoryFlag.set(true)}
            disabled={historyLength === 3 ? false : true}
          >
            <ExpandMoreIcon />
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </React.Fragment>
  );
};

History.producers([getDecisionHistoryProducers, GetAllHistoryProducer]);

export default History;
