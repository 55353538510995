const LoginProducer: producer = async ({
  pca = get.pca,
  getlogin = observe.login,
  setlogin = update.login,
}) => {
  if (!getlogin) return;

  pca.value().loginRedirect();
  setlogin.set(false);
};

export default LoginProducer;
