import TextFieldComponent from "../../packageView/components/tableDialog/textFieldComponent";
import {path} from "@c11/engine.runtime";
import VirtualizedTable from "../../../common/table";
import Button from "@mui/material/Button";

export const SettingTable: view = ({
  unitMap = observe.calculations.unitMap,
  updateUnitMap = update.calculations.unitMap
}) => {
  let columns = [
    {
      width: 150,
      label: "Name",
      dataKey: "name",
      numeric: false,
    },
    {
      width: 180,
      label: "CAD Name",
      dataKey: "CADunitName",
      numeric: false,
    },
    {
      width: 140,
      label: "Unit number",
      dataKey: "unitNumber",
      numeric: false,
    }, {
      width: 150,
      label: "",
      dataKey: "newLine",
      numeric: false,
    }
  ]
  const rows: any[] = (unitMap || []).map(({ name, unitNumber, CADunitName }:any, index: number) => {
    return {
      name,
      unitNumber,
      CADunitName: <TextFieldComponent index={index} path={path.calculations.unitMap[index].CADunitName} />,
      newLine: <Button onClick={() => {
        unitMap.splice((index + 1), 0, {...unitMap[index], CADunitName: null, mappingId: null})
        updateUnitMap.set(unitMap)
      }}>{`+`}</Button>
    }
  })


  return (
    <div style={{ height: 'calc(100vh - 220px)', width: 'calc(100vw - 100px)', overflow: 'hidden' }}>
      <VirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        columns={columns}
      />
    </div>
  )
}
