import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

const MyTableHead: view = ({
  setSyncAllDecisions = update.syncAllDecisions,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>Decision Name</TableCell>
        <TableCell align="center">New Value</TableCell>
        <TableCell align="center">Description</TableCell>
        <TableCell align="center">Was Processed</TableCell>
        <TableCell align="center">Update Condition</TableCell>
        <TableCell align="center">Created</TableCell>
        <TableCell align="center">
          <Button
            variant="contained"
            size="medium"
            onClick={() => setSyncAllDecisions.set(true)}
          >
            SYNC ALL
          </Button>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MyTableHead;
