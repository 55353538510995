const axios = require("axios");

import { baseURL, getAzToken } from "../../../../../config/azToken";

const GetAllHistoryProducer: producer = async ({
  decisionName = prop.decisionName,
  getLoadMoreHistoryFlag = observe.loadMoreHistoryFlag,
  setLoadMoreHistoryFlag = update.loadMoreHistoryFlag[prop.decisionName],
  getHistory = observe.histories,
  setHistory = update.histories[prop.decisionName],
}) => {
  if (!getLoadMoreHistoryFlag[decisionName]) return;

  const payload = {
    method: "get",
    url: "/allHistory",
    baseURL,
    params: { code: getAzToken(), decision: decisionName },
    headers: {
      "content-type": "application/json",
    },
    responseType: "json",
  };

  setLoadMoreHistoryFlag.set(false);

  try {
    const { data } = await axios(payload);
    const completeHistory = [...getHistory[decisionName], ...data];
    setHistory.set(completeHistory);
  } catch (e) {
    console.log(e);
  }
};

export default GetAllHistoryProducer;
