import { BrowserRouter, Routes, Route } from "react-router-dom";
import DecisionsView from "./views/decisionsView/decisionsView";
import CalculationSettings from "./views/calculationView/views/settingsView"
import HomeView from "./views/homeView/homeView";
import Navbar from "./auth/navbar";
import Message from "./common/message/message";
import Auth from "./auth/loadingScreen";
import PackageCalculationView from "./views/packageView/packageCalculationView";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

export const App: view = ({}) => {
  return (
    <BrowserRouter>
      <>
        <Navbar />
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/home" element={<HomeView />} />
            <Route path="/calculations/settings" element={<CalculationSettings />} />
            <Route path="/decisions" element={<DecisionsView />} />
            <Route
              path="/packageCalculation"
              element={<PackageCalculationView />}
            />
            <Route path="/" element={<Auth />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Message message={"Please Log In"} />
        </UnauthenticatedTemplate>
      </>
    </BrowserRouter>
  );
};

App.producers([]);
