const axios = require("axios");

import { functionAppURL, getAzToken } from "../../../config/azToken";

const SyncAllDecisionsProducer: producer = async ({
  getSyncAllDecisionsFlag = observe.syncAllDecisions,
  setSyncAllDecisionsFlag = update.syncAllDecisions,
  getDecisions = get.decisions,
  setDecisions = update.decisions,
}) => {
  if (!getSyncAllDecisionsFlag) return;

  const payload = {
    method: "post",
    url: `${functionAppURL}/decisions/syncAll`,
    params: { code: getAzToken() },
    headers: {
      "content-type": "application/json",
    },
    responseType: "json",
  };

  setSyncAllDecisionsFlag.set(false);

  try {
    await axios(payload);

    const _d = getDecisions.value();
    const decisions = Object.keys(_d).map((key: any) => {
      return { ..._d[key], WasProcessed: true };
    });
    setDecisions.set(decisions);
  } catch (e) {
    console.log(e);
    return;
  }
};

export default SyncAllDecisionsProducer;
