import * as Msal from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "f8e1c69b-71d2-4796-85ec-e3c5f3c107ad",
    authority:
      "https://xbobwb2c.b2clogin.com/xbobwb2c.onmicrosoft.com/B2C_1A_signup_signin_OBOS", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
    validateAuthority: false,
    knownAuthorities: ["xbobwb2c.b2clogin.com"],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: window.location.origin,
  },
  system: {
    loggerOptions: {
      logLevel: Msal.LogLevel.Warning,
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message);
            return;
          case Msal.LogLevel.Info:
            console.info(message);
            return;
          case Msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case Msal.LogLevel.Warning:
            console.warn(message);
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  scopes: ["openid"],
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};
