import React from 'react'
const axios = require("axios");

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { functionAppURL, getAzToken } from "../../config/azToken";

export const CalculationCard: view = ({
  getUploadStatus = observe.uploadStatus,
  setUploadStatus = update.uploadStatus,
  pca = get.pca,
}) => {
  const currentAccount = pca.value().getAllAccounts()[0];
  const { email: userEmail } = currentAccount.idTokenClaims;

  const payload = (data: any) => {
    return {
      method: "post",
      url: `${functionAppURL}/route/calculation`,
      params: { code: getAzToken() },
      headers: {
        "Content-Type": "multipart/form-data",
        From: userEmail,
      },
      data,
      responseType: "json",
    };
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const files = e.target.files;
    const formData = new FormData();
    Object.keys(files).map((key: any) => {
      const file = files[key] || {};
      formData.append(`file_${key}`, file, file.name);
    });

    setUploadStatus.set("In Progress");
    const { message } = (await axios(payload(formData))).data;
    message === "files uploaded"
      ? setUploadStatus.set("Done")
      : "calculation in progress"
      ? setUploadStatus.set("Already in progress")
      : setUploadStatus.set("Failed");
  };

  return (
    <Card sx={{ maxHeight: 300, maxWidth: 270 }}>
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          Calculation Activity
        </Typography>
        <Typography variant="h4" component="div">
          Start Full Calculation
        </Typography>
        <Typography variant="body1">
          On this card you can upload Revit files to start a full calculation
        </Typography>
        <Typography
          variant="h6"
          color={
            getUploadStatus === "Done"
              ? "green"
              : getUploadStatus === ""
              ? "text.secondary"
              : getUploadStatus === "In Progress"
              ? "blue"
              : "red"
          }
        >
          {!getUploadStatus ? "Status" : getUploadStatus}
        </Typography>
        <br />
        <a href={'/calculations/settings'}>
          <Button size="large">Settings</Button>
        </a>
        <Button variant="contained" component="label">
          Upload
          <form>
            <input
              hidden
              accept=".csv"
              multiple
              type="file"
              onChange={handleChange}
            />
          </form>
        </Button>
      </CardContent>
    </Card>
  );
};

