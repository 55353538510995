const axios = require("axios");
import { functionAppURL, getAzToken } from "../config/azToken";

let payload = {
  method: "post",
  url: `${functionAppURL}/route/getBuildings`,
  params: { code: getAzToken() },
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
};

export const postUrl = async ({ url, data}: { url:string, data?:any }) => {
  payload = {
    ...payload,
    url: `${functionAppURL}${url}`,
    ...(data? { data: { ...data } } : null)
  }
  const response = await axios(payload)
  return response.data
}
