import React, { useState } from 'react';
import { getBuildings, getBuildingUnits, saveMappings  } from "../producers"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import { SettingTable } from "../components/settingsTable"
import SimpleLoadingView from "../../../common/simpleLoading/SimpleLoadingView";

const CalculationSettings: view = ({
  options = observe.calculations.options,
  triggerSave = update.calculations.save,
  setBuildingProjectNumber = update.calculations.buildingProjectNumber
}) => {
  const [value, setValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  if (!options) {
    return <SimpleLoadingView message={"Waking up Azure functions..."} />;
  }

  return (
    <div style={{ padding: '1rem', display: 'flex',  flexDirection:"column" }} >
      <div style={{display:'flex', justifyContent: 'center'}} >
        <Autocomplete
          id="building-select"
          sx={{ width: 300 }}
          options={options || []}
          autoHighlight
          value={value || null}
          onChange={(event: any, newValue: any | null) => {
            if (newValue === null) {
              setBuildingProjectNumber.remove()
            } else {
              setBuildingProjectNumber.set(newValue)
            }
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option: any) => option.projectNumber}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose Building"
            />
          )}
        />
      </div>
      {value
        ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SettingTable />
            <Stack direction="row" spacing="2" justifyContent="center">
              <Button variant="contained" onClick={() => triggerSave.set(true)}>Save</Button>
            </Stack>
          </div>
        )
        : (
          <div style={{ padding: '5rem', display: 'flex', justifyContent: 'center'}}>
            Building not selected
          </div>
        )
      }
    </div>
  )
}
CalculationSettings.producers([getBuildings, getBuildingUnits, saveMappings ])


export default CalculationSettings
