import Message from "../message/message";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const SimpleLoadingView: view = ({ message = prop.message }) => {
  return (
    <>
      <LinearProgress color="secondary" />
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Message message={message} />
      </Box>
    </>
  );
};

export default SimpleLoadingView;
