import React, { useEffect } from 'react'
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const TextFieldComponent: view = ({
  name,
  stateValue = observe[prop.path],
  setStateValue = update[prop.path],
 }) => {
  const [ value, setValue ] = React.useState(stateValue)

  const updateValue = (value: string) => {
    setValue(value)
    setStateValue.set(value)
  }

  useEffect(() => {
    setValue(stateValue)
  }, [stateValue])

  return (
    <Box sx={{ minWidth: 50 }}>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          inputProps={{ "aria-label": "Without label" }}
          id={name}
          type="tel"
          value={value || ''}
          onChange={({ target }) => {
            updateValue(target.value)
          }
          }
        />
      </FormControl>
    </Box>
  );
};

export default TextFieldComponent;
