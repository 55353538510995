import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";

import CustomForm from "../customForm/customForm";
import template from "../../utils/sqlTemplate";

import NewDecisionProducer from "./producers/newDecisionProducer";

const DialogWindow: view = ({
  setAddNewDecisionFlag = update.addNewDecisionFlag,
  isDialog = observe.dialog,
  setDialog = update.dialog,
  getSelect = observe.select,
  setSelect = update.select,
  getNewDecision = observe.newDecision,
  getDecisionData = observe.decisionData,
}) => {
  if (!Object.keys(getDecisionData).length) return;

  const selectedDecision: any = getNewDecision["DecisionName"] || null;
  const { linkedTable } = template[selectedDecision] || { linkedTable: [] };

  const decisionData = getDecisionData[selectedDecision];
  const _newValue = selectedDecision
    ? template[selectedDecision]["newValue"](decisionData)
    : [];
  const _currentValue = selectedDecision
    ? template[selectedDecision]["currentValue"](decisionData)
    : [];

  const decisions = Object.keys(template).map((d) => {
    return { value: d, label: template[d]["decisionName"]["label"] };
  });

  const decisionSelect = {
    value: "DecisionName",
    type: "select",
    data: {
      map: "#name#",
      name: "Decision Name",
      options: decisions,
    },
  };
  const inputForms = (linkedTable: any, newValue: any, currentValue: any) => [
    {
      value: "Description",
      type: "text",
      data: {
        name: "Description",
      },
    },
    {
      value: "LinkedTable",
      type: "select",
      data: {
        map: "#group#",
        name: "Group",
        options: linkedTable,
      },
    },
    {
      value: "Value",
      type: "select",
      data: {
        map: "#newValue#",
        name: "New Value",
        options: newValue,
      },
    },
    {
      value: "UpdateCondition",
      type: "select",
      data: {
        map: "#currentValue#",
        name: "Update On",
        options: currentValue,
      },
    },
  ];

  return (
    <div>
      <Fab
        color="primary"
        size="small"
        aria-label="add"
        sx={{ marginTop: "10px" }}
        onClick={() => setDialog.set(true)}
      >
        +
      </Fab>
      <Dialog
        open={isDialog || false}
        onClose={() => {
          setDialog.set(false);
        }}
      >
        <DialogTitle>New Decision</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new decision please complete the form below.
          </DialogContentText>
          <p></p>
          <CustomForm
            type={decisionSelect.type}
            value={decisionSelect.value}
            data={decisionSelect.data}
          />
          {selectedDecision ? (
            inputForms(linkedTable, _newValue, _currentValue).map((form) => (
              <CustomForm
                type={form.type}
                key={form.value}
                value={form.value}
                data={form.data}
              />
            ))
          ) : (
            <></>
          )}
          <p></p>
          <p>
            {getSelect.replaceAll(
              /#name#|#newValue#|#group#|#currentValue#/g,
              " ... "
            )}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelect.set(
                "Update #name# with #newValue# where #group# is currently #currentValue#"
              );
              setDialog.set(false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setSelect.set(
                "Update #name# with #newValue# where #group# is currently #currentValue#"
              );
              setAddNewDecisionFlag.set(true);
              setDialog.set(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogWindow.producers([NewDecisionProducer]);

export default DialogWindow;
