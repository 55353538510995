const axios = require("axios");

import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import TableDialog from "./components/tableDialog/tableDialog";
import PackageCalculationController from "./components/tableDialog/producers/packageCalculationController";
import SimpleLoadingView from "../../common/simpleLoading/SimpleLoadingView"

import { functionAppURL, getAzToken } from "../../config/azToken";

const hasPackets = (list:any) => {
  if (!list) return false
  return !!Object.keys(list).length
}
const PackageCalculationView: view = ({
  pca = get.pca,
  setShowTable = update.isTable,
  packets = get.packageTable.packets,
  projectNr = get.packageTable.projectNumber,
  setPackageTable = update.packageTable.packets,
  getLoadingPackages = observe.loadingPackages,
  getPackagesStatus = observe.packagesStatus,
  setPackagesStatus = update.packagesStatus,
  getPackageFinalStep = observe.packageFinalStep,
  setPackageFinalStep = update.packageFinalStep,
  packageCalculationError = observe.packageCalculationError,
  removeError = update.packageCalculationError
}) => {
  if (getLoadingPackages)
    return <SimpleLoadingView message={"Waking up Azure functions..."} />;
  const isSecondStep = hasPackets(packets.value())
  const currentAccount = pca.value().getAllAccounts()[0];
  const { email: userEmail } = currentAccount.idTokenClaims;

  const payload = (data: any) => {
    return {
      method: "post",
      url: `${functionAppURL}/route/preCalculation`,
      params: { code: getAzToken() },
      headers: {
        "Content-Type": "multipart/form-data",
        From: userEmail,
      },
      data,
      responseType: "json",
    };
  };

  const handleChange = async (e: any) => {
    const files = e.target.files;
    const formData = new FormData();
    Object.keys(files).map((key: any) => {
      const file = files[key] || {};
      formData.append(`file_${key}`, file, file.name);
    });

    localStorage.removeItem('filepath')
    const { message, filePath } = (await axios(payload(formData))).data;
    localStorage.setItem('filepath', filePath)
    message === "calculation in progress"
      ? setPackagesStatus.set("Calculation already in progress")
      : setPackagesStatus.set(
          "Upload done, you will recieve an email when is done"
        );
  };

  const packagePayload = (packages: any) => {
    const filepath = localStorage.getItem('filepath')
    return {
      method: "post",
      url: `${functionAppURL}/route/packageCalculation`,
      params: { code: getAzToken() },
      headers: {
        "content-type": "application/json",
        From: userEmail,
        filepath
      },
      data: { packages },
      responseType: "json",
    };
  };

  const resetCalculationPayload = {
    method: "post",
    url: `${functionAppURL}/route/resetPackageCalculation`,
    params: { code: getAzToken() },
    headers: {
      "content-type": "application/json",
    },
    responseType: "json",
  };

  const flow = [
    {
      title: "Upload Files",
      subTitle: "First step",
      icon: <CloudUploadIcon />,
      enabled: true,
      action: (
        <Button variant="contained" component="label">
          Upload
          <form>
            <input
              hidden
              accept="*.*"
              multiple
              type="file"
              onClick={(e: any) => (e.target.value = "")}
              onChange={handleChange}
            />
          </form>
        </Button>
      ),
    },
    {
      title: "Select Quantities",
      subTitle: "Second step",
      projectNumber: `Project number: ${projectNr.value()}`,
      icon: <AssignmentIcon />,
      enabled: isSecondStep,
      action: (
        <div>
          <Button
            disabled={!isSecondStep}
            variant="contained"
            component="label"
            onClick={() => setShowTable.set(true)}
          >
            View Table
          </Button>
        </div>
      ),
    },
    {
      title: "Start Calculation",
      subTitle: "Final step",
      icon: <CloudUploadIcon />,
      enabled: getPackageFinalStep,
      action: (
        <Button
          disabled={!isSecondStep}
          onClick={async () => {
            const packagesArray: Array<Object> = [];
            Object.keys(packets.value()).map((index) => {
              packagesArray.push(packets.value()[index]);
            });
            const { message } = (await axios(packagePayload(packagesArray)))
              .data;
            setPackageFinalStep.set(true);
            message
              ? setPackagesStatus.set(
                  "Calculation started, you will be notified via email when it is done"
                )
              : setPackagesStatus.set("Calculation failed");
          }}
          variant="outlined"
          size="medium"
        >
          Start
        </Button>
      ),
    },
  ];
  return (
    <div>
      <TableDialog />
      <Timeline position="alternate">
        {flow.map((entry: any) => {
          return (
            <TimelineItem key={flow.indexOf(entry)}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {entry.action}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  style={
                    entry.enabled
                      ? { backgroundColor: "rgb(25 118 210)" }
                      : { backgroundColor: "grey" }
                  }
                >
                  {entry.icon}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "40px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {entry.title }
                </Typography>
                <Typography>
                  {entry.projectNumber &&
                    <>
                      <span>{entry.projectNumber}</span>
                      <br/>
                    </>
                  }
                  <span>{entry.subTitle}</span>
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="center"
            variant="body2"
            color="text.secondary"
          >
            <TimelineSeparator>
              <TimelineConnector />
              <Typography variant="h6" component="span">
                {`Status: ${getPackagesStatus}`}
              </Typography>
              <TimelineConnector />
            </TimelineSeparator>
          </TimelineOppositeContent>
        </TimelineItem>
      </Timeline>
      <Snackbar open={!!packageCalculationError} autoHideDuration={6000} onClose={() => removeError.remove() }>
        <Alert onClose={() => removeError.remove()} severity="error" sx={{ width: '100%' }}>
          {`(${packageCalculationError?.statusCode}) ${packageCalculationError?.message}`}
        </Alert>
      </Snackbar>
    </div>
  );
};

PackageCalculationView.producers([PackageCalculationController]);
export default PackageCalculationView;
