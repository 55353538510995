import Typography from "@mui/material/Typography";

const Message: view = ({ message = prop.message }) => {
  return (
    <Typography
      variant="h6"
      noWrap
      component="a"
      sx={{
        mr: 2,
        display: { xs: "none", md: "flex" },
        fontFamily: "monospace",
        fontWeight: 700,
        letterSpacing: ".3rem",
        color: "inherit",
        textDecoration: "none",
        justifyContent: "space-around",
        marginTop: "200px",
      }}
    >
      {message}
    </Typography>
  );
};

export default Message;
