import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ReactVirtualizedTable from "./virtualizedTable";
import TextFieldComponent from "./textFieldComponent";
import SelectComponent from "./selectComponent";
import ButtonComponent from "./buttonComponent";
import { path } from '@c11/engine.runtime'

const TableDialog: view = ({
  setShowTable = update.isTable,
  getShowTable = observe.isTable,
  refreshTable = observe.refreshTable,
  setPackageTable = update.packageTable.packets,
  setPackagesStatus = update.packagesStatus,
  getFloors = observe.packageTable.floors,
  getPackets = get.packageTable.packets,
}) => {
  if (!getShowTable) return null

  const rows: any[] = Object.keys(getPackets.value()).map((index: any) => {
    return {
      ...getPackets.value()[index],
      ...(getFloors.reduce((acc: any, floor: string) => {
        acc[floor] = <TextFieldComponent path={path.packageTable.packets[index][floor]} />
        return acc
      }, {})),
      apartment: <SelectComponent index={index} name={'apartments'}/>,
      module: <SelectComponent index={index} name={'modules'}/>,
      newLine: <ButtonComponent index={index} />
    };
  });

  return (
    <div>
      <Dialog
        open={getShowTable}
        keepMounted
        onClose={() => {}}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
      >
        <DialogTitle>{"Package Selection"}</DialogTitle>
        <DialogContent>
          {/* <TableComponent /> */}
          {!Object.keys(getPackets.value()).length ? (
            <p>Please first upload a Revit source file</p>
          ) : (
            ReactVirtualizedTable(rows, getFloors)
          )}
        </DialogContent>
        {!Object.keys(getPackets.value()).length ? (
          <DialogActions>
            <Button
              onClick={() => {
                setShowTable.set(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                setPackagesStatus.set(
                  "Quantities set, you can start the calculation now"
                );
                setShowTable.set(false);
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setPackagesStatus.set(
                  "Quantities table was closed without saving"
                );
                setPackageTable.set(getPackets.value().map(({ packetNumber, packetName, packetUnit }: any) => ({ packetNumber, packetName, packetUnit })));
                setShowTable.set(false);
              }}
            >
              Clear
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default TableDialog;
