import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import Row from "./components/row/row";
import MyTableHead from "./components/tableHead/myTableHead";
import DialogWindow from "./components/dialog/dialog";
import EmptyTable from "./components/emptyTable/emptyTable";

import GetTableDataProducer from "./producers/getTableDataProducer";
import SyncAllDecisionsProducer from "./producers/syncAllDecisionsProducer";
import GetDecisionDataProducer from "./producers/getDecisionDataProducer";
import SimpleLoadingView from "../../common/simpleLoading/SimpleLoadingView";

const DecisionsView: view = ({ getDecisions = observe.decisions }) => {
  if (!Object.keys(getDecisions).length)
    return (
      <SimpleLoadingView message={"loading... Waking up cloud functions"} />
    );

  if (getDecisions.size === 0) return <EmptyTable />;

  const rows = Object.keys(getDecisions)
    .map((key: any) => getDecisions[key])
    .sort(
      (a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime()
    );

  return (
    <div>
      <Stack direction="row" spacing={2}>
        <DialogWindow />
      </Stack>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <MyTableHead />
          <TableBody>
            {rows.map((row: any) => (
              <Row key={rows.indexOf(row)} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

DecisionsView.producers([
  GetTableDataProducer,
  GetDecisionDataProducer,
  SyncAllDecisionsProducer,
]);

export default DecisionsView;
