const axios = require("axios");

import { baseURL, addAzToken, getAzToken } from "../../config/azToken";

const GetAzTokenProducer: producer = async ({ pca = get.pca }) => {
  if (getAzToken()) {
    window.location.href = "/home";
    return;
  }

  const currentAccount = await pca.value().getAllAccounts()[0];
  const accessTokenRequest = {
    account: currentAccount,
  };
  const { idToken } = await pca.value().acquireTokenSilent(accessTokenRequest);

  const url = "/aztokens";
  const payload = {
    method: "get",
    url,
    baseURL: baseURL,
    headers: {
      "content-type": "application/json",
      key: "x-frontend-app",
      value: "calcsys-app",
      Authorization: `Bearer ${idToken}`,
    },
    responseType: "json",
  };

  const resp = await axios.request(payload);
  addAzToken(resp);
  window.location.replace("/home");
};

export default GetAzTokenProducer;
