const axios = require("axios");

import { functionAppURL, getAzToken } from "../../../../../config/azToken";

const NewDecisionProducer: producer = async ({
  getNewDecision = observe.newDecision,
  setNewDecision = update.newDecision,
  getAddNewDecisionFlag = observe.addNewDecisionFlag,
  setAddNewDecisionFlag = update.addNewDecisionFlag,
  getDecisions = get.decisions,
  setDecisions = update.decisions,
  resetIsOpen = update.isOpen,
  resetHistory = update.histories,
}) => {
  // TODO fix wierd refresh add flag for getTableDataProducer
  if (!getAddNewDecisionFlag) return;

  const newDecision = { ...getNewDecision, Created: new Date() };

  const payload = {
    method: "post",
    url: `${functionAppURL}/decisions/new`,
    params: { code: getAzToken() },
    headers: {
      "content-type": "application/json",
    },
    data: { ...newDecision },
    responseType: "json",
  };

  try {
    await axios(payload);

    const decisions = {
      ...getDecisions.value(),
      [newDecision.DecisionName]: newDecision,
    };

    // const sortedDecisions: { [key: string]: object } = {};
    // const rows = Object.keys(decs)
    //   .map((key: any) => decs[key])
    //   .sort(
    //     (a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime()
    //   )
    //   .map((d: any) => (sortedDecisions[d.DecisionName] = d));
    // console.log(rows.map((d) => d.DecisionName));

    setDecisions.set(decisions);
    setNewDecision.set({});
    resetIsOpen.set({});
    resetHistory.set({});
    setAddNewDecisionFlag.set(false);
  } catch (e) {
    console.log(e);
  }
};

export default NewDecisionProducer;
