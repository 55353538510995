import { postUrl } from "../../../services/functionAppService"

export const getBuildings: producer = async ({
  setOptions = update.calculations.options
}) => {
  const response = await postUrl({ url: '/route/getBuildings' })
  setOptions.set(response)
}

export const getBuildingUnits: producer = async ({
  buildingProjectNumber = observe.calculations.buildingProjectNumber.projectNumber,
  setUnitMap = update.calculations.unitMap
}) => {
  if (!buildingProjectNumber) return
  setUnitMap.set([])
  const response = await postUrl({
    url: '/route/getBuildingsUnits',
    data: { projectNumber: buildingProjectNumber }
  })
  setUnitMap.set(response)
}

export const saveMappings: producer = async ({
  trigger = observe.calculations.save,
  removeTrigger = update.calculations.save,
  unitMap = get.calculations.unitMap,
  setUnitMap = update.calculations.unitMap
}) => {
  if (!trigger) return
  removeTrigger.remove()

  const response = await postUrl({
    url: '/route/addCADmapping',
    data: { list: unitMap.value() }
  })
  setUnitMap.set(response)
}
