import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";

const CustomForm: view = ({
  type = prop.type,
  setNewDecision = update.newDecision[prop.value],
  data = prop.data,
  getSelect = observe.select,
  setSelect = update.select,
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        {type === "text" ? (
          <TextField
            id="standard-basic"
            label={data.name}
            defaultValue={""}
            variant="standard"
            onChange={(event) => {
              setNewDecision.set(event.target.value);
            }}
          />
        ) : (
          <>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {data.name}
            </InputLabel>
            <NativeSelect
              defaultValue={""}
              onChange={(event) => {
                setNewDecision.set(event.target.value);
                // const _val = event.target.value.replace("Change ", "");
                const { label: _label } = data.options.find(
                  ({ value }: { value: any }) =>
                    String(value) === event.target.value
                );
                setSelect.set(getSelect.replace(data.map, _label));
              }}
            >
              <option hidden={true} value={""}>
                {""}
              </option>
              {data.options.map((d: any) => (
                <option key={data.options.indexOf(d)} value={d.value}>
                  {d.label}
                </option>
              ))}
            </NativeSelect>
          </>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomForm;
