const __STAGING__ =  /\.io$/.test(window.location.hostname)
const __PROD__ =  /\.no$/.test(window.location.hostname)

const functionAppURL = (__STAGING__ || __PROD__)
  ? __PROD__
    ? "https://cid01-p2-euw-calcsysbusinesslogicfunctions-01-fnc.azurewebsites.net/api"
    : "https://cid01-p-euw-calcsysbusinesslogicfunctions-01-fnc.azurewebsites.net/api"
  : "/appUrl/api"
const baseURL = "/api/";


const addAzToken = ({ data }: any) => {
  let token = data?.find((t: any) => Object.keys(t)[0] === "OBW_Calc_Standard")
  if (token) {
    const { OBW_Calc_Standard } = token;
    localStorage.setItem("OBW_role", "OBW_Calc_Standard")
    localStorage.setItem("OBW_token", OBW_Calc_Standard);
  }
  token = data?.find((t: any) => Object.keys(t)[0] === "OBW_Calc_Admin")
  if (token) {
    const { OBW_Calc_Admin } = token;
    localStorage.setItem("OBW_role", "OBW_Calc_Admin")
    localStorage.setItem("OBW_token", OBW_Calc_Admin);
  }
};

const getAzToken = () => {
  return localStorage.getItem("OBW_token");
};

const checkAzToken = () => {
  return localStorage.getItem("OBW_token") ? true : false;
};

const deleteAzToken = () => {
  localStorage.removeItem("OBW_token");
  localStorage.removeItem("OBW_role");
};

const isAdmin = () => {
  return localStorage.getItem("OBW_role") === "OBW_Calc_Admin"
}
export { baseURL, functionAppURL, addAzToken, getAzToken, checkAzToken, deleteAzToken, isAdmin };
