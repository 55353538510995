const axios = require("axios");

import { functionAppURL, getAzToken } from "../../../config/azToken";

const GetTableDataProducer: producer = async ({
  getDecisions = observe.decisions,
  setDecisions = update.decisions,
}) => {
  if (getDecisions.length) return;

  const payload = {
    method: "get",
    url: `${functionAppURL}/decisions`,
    params: { code: getAzToken() },
    headers: {
      "content-type": "application/json",
    },
    responseType: "json",
  };

  try {
    const response = (await axios(payload)).data.reduce(
      (a: any, v: any) => ({ ...a, [v.DecisionName]: v }),
      {}
    );

    !Object.keys(response).length
      ? setDecisions.set({ size: 0 })
      : setDecisions.set(response);
  } catch (e) {
    console.log(e);
  }
};

export default GetTableDataProducer;
