import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

const ButtonComponent: view = ({
   index,
   refreshCounter = get.refreshTable,
   triggerRefresh = update.refreshTable,
   getPackets = get.packageTable.packets,
   setPackets = update.packageTable.packets,
}) => {
  return (
    <FormControl fullWidth size="small">
      <Button onClick={() => {
        const packets = getPackets.value()
        packets.splice((parseInt(index) + 1), 0, {
          packetNumber: packets[index].packetNumber,
          packetName: packets[index].packetName,
          packetUnit:  packets[index].packetUnit
        })
        setPackets.set(packets)
        triggerRefresh.set(parseInt(refreshCounter.value() || '0') + 1)
      }}>
        {`+`}
      </Button>
    </FormControl>
  );
};

export default ButtonComponent;
