import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

const CardComponent: view = ({ data = prop.data }) => {
  const {
    activityName,
    cardTitle,
    cardSubtitle,
    description,
    path,
  }: {
    activityName: string;
    cardTitle: string;
    cardSubtitle: string;
    description: string;
    path: string;
  } = data;

  return (
    <Card sx={{ maxHeight: 300, maxWidth: 270 }}>
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {activityName}
        </Typography>
        <Typography variant="h4" component="div">
          {cardTitle}
        </Typography>
        <Typography sx={{ mb: 3.5 }} color="text.secondary">
          {cardSubtitle}
        </Typography>
        <Typography variant="body1">
          {description}
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <a href={path}>
          <Button size="large">Go</Button>
        </a>
      </CardActions>
    </Card>
  );
};

export default CardComponent;
