const axios = require("axios");

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { functionAppURL,  getAzToken } from "../../config/azToken";

const PopulationCard: view = ({
  getPopulationStatus = observe.populationStatus,
  setPopulationStatus = update.populationStatus,
  pca = get.pca,
}) => {
  const currentAccount = pca.value().getAllAccounts()[0];
  const { email: userEmail } = currentAccount.idTokenClaims;

  const payload = (data: any) => {
    return {
      method: "post",
      url: `${functionAppURL}/route/population`,
      params: { code: getAzToken() },
      headers: {
        "Content-Type": "multipart/form-data",
        From: userEmail,
      },
      data,
      responseType: "json",
    };
  };

  const handleChange = async (e: any) => {
    const files = e.target.files;
    const formData = new FormData();
    Object.keys(files).map((key: any) => {
      const file = files[key] || {};
      formData.append(`file_${key}`, file, file.name);
    });

    console.log("Files sent to upload");
    setPopulationStatus.set("In Progress");
    const { message } = (await axios(payload(formData))).data;
    message === "calculation in progress"
      ? setPopulationStatus.set("Already in progress")
      : setPopulationStatus.set("Done");
  };

  return (
    <Card sx={{  maxWidth: 300 }}>
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          Population Activity
        </Typography>
        <Typography variant="h4" component="div">
          Start Populating
        </Typography>
        {/* <Typography sx={{ mb: 3.5 }} color="text.secondary">
          Run
        </Typography> */}
        <Typography variant="body1">
          Upload new files to set a new calculation source
        </Typography>
        <Typography
          variant="h6"
          color={
            getPopulationStatus === "Done"
              ? "green"
              : getPopulationStatus === ""
              ? "text.secondary"
              : getPopulationStatus === "In Progress"
              ? "blue"
              : "red"
          }
        >
          {!getPopulationStatus ? "Status" : getPopulationStatus}
        </Typography>
        <br />
        <FormGroup>
          <FormControlLabel control={<Checkbox defaultChecked />} label="Full calculation" />
          <FormControlLabel control={<Checkbox defaultChecked />} label="Prices" />
          <FormControlLabel control={<Checkbox defaultChecked />} label="Cost estimation" />
        </FormGroup>
        <br />
        <Button variant="contained" component="label">
          Upload
          <form>
            <input
              hidden
              accept=".*"
              multiple
              type="file"
              onClick={(e: any) => (e.target.value = "")}
              onChange={handleChange}
            />
          </form>
        </Button>
      </CardContent>
    </Card>
  );
};

export default PopulationCard;
