import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectComponent: view = ({
  name,
  list = get.packageTable[prop.name],
  row = observe.packageTable.packets[prop.index][prop.name],
  setPackageTable = update.packageTable.packets[prop.index][prop.name],
}) => {
  return (
    <Box sx={{ minWidth: 100 }}>
      <FormControl fullWidth size="small">
        <Select
          inputProps={{ "aria-label": "Without label" }}
          labelId={name}
          id={name}
          value={row || ''}
          onChange={({ target }) =>
            setPackageTable.set(target.value)
          }
        >
          {list.value().map((i: string) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectComponent;
