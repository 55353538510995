import { engine } from "@c11/engine.runtime";
import { render } from "@c11/engine.react";
import { App } from "./App";
import "./global";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const app = engine({
  state: {
    pca: msalInstance,
    decisions: {},
    decisionData: {},
    histories: {},
    loadMoreHistoryFlag: {},
    newDecision: {},
    addNewDecisionFlag: false,
    syncOneDecisionFlag: {},
    syncAllDecisions: false,
    isOpen: {},
    didalog: false,
    uploadStatus: "",
    costEstUploadStatus: "",
    populationStatus: "",
    dropdown: {},
    login: false,
    logout: false,
    azToken: {},
    triggerRedirect: false,
    isTable: false,
    packageTable: [],
    loadingPackages: true,
    packagesStatus: " --- ",
    packageFinalStep: false,
    select:
      "Update #name# with #newValue# where #group# is currently #currentValue#",
  },
  use: [
    render(
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>,
      "#app",
      {
        debug: false,
      }
    ),
  ],
});

app.start();

// Each renderer can have a state where it stores if it finished rendering/mounting/etc
// This can be used to hook-up processes for export for example
