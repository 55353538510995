const axios = require("axios");

import { functionAppURL, getAzToken} from "../../../../../config/azToken";

const PackageCalculationController: producer = async ({
  getPackageTable = observe.packageTable,
  setPackageTable = update.packageTable,
  setLoadingPackages = update.loadingPackages,
  setPackageCalculationError = update.packageCalculationError
}) => {
  if (Object.keys(getPackageTable).length) {
    return;
  }
  const filepath = localStorage.getItem('filepath')
  const payload = {
    method: "post",
    url: `${functionAppURL}/route/packageCalculation`,
    params: { code: getAzToken() },
    headers: {
      "content-type": "application/json",
      filepath
    },
    responseType: "json",
  };

  try {
    const { data } = await axios(payload)
      .catch((e : any) => {
        setPackageCalculationError.set({
          message: `route/packageCalculation - ${e.response.data.message}`,
          statusCode: e.response.status
        })
        setLoadingPackages.set(false);
        return;
      })

    const packages = data
    if (packages?.message?.includes("missing")) {
      setLoadingPackages.set(false);
      return;
    }
    // Maybe we should do all the transformation here ?
    setPackageTable.set({ ...packages, packets: packages.packets.sort((a:any, b:any) => {
        let first = a.packetNumber.split('.')
        let next = b.packetNumber.split('.')
        if (first[0] > next[0]) return 1
        if (first[0] < next[0]) return -1

        if (first[1] > next[1]) return 1
        if (first[1] < next[1]) return -1

        if (first[2] > next[2]) return 1
        if (first[2] < next[2]) return -1
        return -1
      })
    });
    setLoadingPackages.set(false);
  } catch (e) {
    console.log(e);
  }
};

export default PackageCalculationController;
