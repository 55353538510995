import Message from "../common/message/message";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import GetAzTokenProducer from "./producers/getAzTokenProducer";

const LoadingScreen: view = ({}) => {
  return (
    <>
      <LinearProgress color="secondary" />
      <Box
        sx={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Message message={"We are setting a few things up..."} />
      </Box>
    </>
  );
};

LoadingScreen.producers([GetAzTokenProducer]);

export default LoadingScreen;
