import React from "react";
import Paper from "@mui/material/Paper";
import VirtualizedTable from "../../../../common/table"


export default function ReactVirtualizedTable(rows: any[], floors: string[]) {
  let columns = [
    {
      width: 150,
      label: "Packet Number",
      dataKey: "packetNumber",
      numeric: true,
    },
    {
      width: 250,
      label: "Packet Name",
      dataKey: "packetName",
      numeric: false,
    },
    {
      width: 100,
      label: "Packet Unit",
      dataKey: "packetUnit",
      numeric: false,
    },
    {
      width: 180,
      label: "Apartment",
      dataKey: "apartment",
      numeric: false,
    },
    {
      width: 180,
      label: "Module",
      dataKey: "module",
      numeric: false,
    }
  ]

  floors.map((floor: string) => {
    columns.push({
      width: 150,
      label: floor,
      dataKey: floor,
      numeric: true,
    })
   })

  columns.push({
    width: 150,
    label: "",
    dataKey: "newLine",
    numeric: false,
  })

  const floorWidth = 800 + floors.length * 150
  return (
    <Paper style={{ height: 700, width: floorWidth }}>
      <VirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        columns={columns}
      />
    </Paper>
  );
}
