import Box from "@mui/material/Box";

import DialogWindow from "../dialog/dialog";
import Message from "../../../../common/message/message";

const EmptyTable: view = ({}) => {
  return (
    <div>
      <Box
        component="span"
        sx={{ display: "flex", mt: "0px" }}
        style={{
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Message message={"There are no records yet"} />
        <p></p>
        <DialogWindow />
      </Box>
    </div>
  );
};

export default EmptyTable;
