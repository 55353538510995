import { deleteAzToken } from "../../config/azToken";

const LogoutProducer: producer = async ({
  pca = get.pca,
  getLogout = observe.logout,
  setLogout = update.logout,
}) => {
  if (!getLogout) return;

  deleteAzToken();
  setLogout.set(false);

  pca.value().logoutRedirect();
};

export default LogoutProducer;
