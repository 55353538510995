const axios = require("axios");

import { functionAppURL, getAzToken} from "../../../config/azToken";
import template from "../utils/sqlTemplate";

const GetDecisionDataProducer: producer = async ({
  getDecisionData = observe.decisionData,
  setDecisionData = update.decisionData,
}) => {
  if (Object.keys(getDecisionData).length) return;

  const payload = (data: any) => {
    return {
      method: "post",
      url: `${functionAppURL}/template/getDecisionData`,
      params: {
        code: getAzToken(),
      },
      headers: {
        "content-type": "application/json",
      },
      data,
      responseType: "json",
    };
  };

  const promises = Object.values(template).map(async (d: any) => {
    return {
      [d.decisionName.value]: await axios(payload(d.dataSource)),
    };
  });

  try {
    const response = await Promise.all(promises);
    const dataCollection: any = {};

    response.map((d: any) => {
      const [key, value]: [string, any] = Object.entries(d)[0];
      dataCollection[key] = value.data;
    });
    setDecisionData.set(dataCollection);
  } catch (e) {
    console.log(e);
  }
};

export default GetDecisionDataProducer;
